body {
  font-family: Jura, cursive;
  margin: 0;
}

.handwriting {
  font-family: Caveat, cursive;
}

.navbar {
  background-image: url('../img/header.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

#navbar {
  padding-bottom: 150px;
  padding-top: 30px;
  width: 100%;
}

#logo {
  background: white;
  border-radius: 100px;
  padding: 0 5px 5px 5px;
}

.nav-link:focus, .nav-link:hover {
  outline: none !important;
}

@media only screen and (min-width: 992px) {
  #logo {
    width: 50%;
  }
}

.navLink {
  color: white;
  font-size: 20px;
}

.navLink:not(.active):after {
  display: block;
  content: '';
  border-bottom: solid 2px white;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.navLink:not(.active-black):hover:after {
  transform: scaleX(1);
}

.navLink:hover {
  cursor: pointer;
}

.home.navLink:after {
  border-bottom: 2px solid #787774;
}

.active-black {
  padding-bottom: 5px;
  border-bottom: 2px solid #787774;
}

.active {
  padding-bottom: 5px;
  border-bottom: 2px solid white;
}

.navLink:hover {
  color: white;
  text-decoration: none;
}

.sidebar {
  background: white;
  top: 0px;
}

.headerContainer {
  margin-top: -10%;
}

@media only screen and (max-width: 992px) {
  .headerContainer {
    margin-top: -18%;
  }
}

@media only screen and (max-width: 767px) {
  .headerContainer {
    margin-top: -26%;
  }
}

@media only screen and (max-width: 500px) {
  .headerContainer {
    margin-top: -32%;
  }
}

@media only screen and (max-width: 400px) {
  .headerContainer {
    margin-top: -38%;
  }
}

.landingHeader {
  background-image: url('../img/header1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100%;
  filter: contrast(150%);
  -webkit-filter: contrast(150%);
}

.header {
  height: 30vh;
  width: 100%;
  background-color: #f1eee8;
}

div.titleSection {
  height: 60vh;
}

h1.headTitle {
  letter-spacing: 3px;
  margin-bottom: 0;
  font-weight: bolder;
  font-size: 100px;
}

@media only screen and (max-width: 992px) {
  h1.headTitle {
    font-size: 80px;
  }
}

@media only screen and (max-width: 768px) {
  h1.headTitle {
    font-size: 60px;
  }
}

@media only screen and (max-width: 530px) {
  h1.headTitle {
    font-size: 50px;
  }
}

@media only screen and (max-width: 440px) {
  h1.headTitle {
    font-size: 43px;
  }
}

@media only screen and (max-width: 400px) {
  h1.headTitle {
    font-size: 37px;
  }
}

@media only screen and (max-width: 350px) {
  h1.headTitle {
    font-size: 32px;
  }
}

@media only screen and (max-width: 320px) {
  h1.headTitle {
    font-size: 28px;
  }
}

.socialIcons {
  width: 3em;
  height: 3em;
  font-weight: 400;
  -webkit-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.socialIcons>a:focus {
  box-shadow: none;
}

.bm-burger-button {
  width: 35px;
  height: 35px;
}

.bm-burger-button>button {
  display: none;
}

.bm-cross {
  background: white;
  height: 23px !important;
  width: 3px !important;
}

.bm-menu-wrap {
  position: fixed;
  height: 100%;
  background: url('../img/header.jpg');
}

.bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

div.tabContent {
  background: url('../img/menu1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: color-dodge;
  background-attachment: fixed;
  background-color: lightgray;
  min-height: 50vh;
}

div.tab {
  width: 40px;
  height: 40px;
}

input, .input-group-text, textarea {
  background-color: white !important;
  border: 1px solid white !important;
  border-radius: 0 !important;
}

div.input, textarea {
  box-shadow: 1px 0px 12px 6px rgba(199, 199, 199, 1);
}

textarea:focus {
  box-shadow: none;
  box-shadow: 1px 0px 12px 6px rgba(199, 199, 199, 1) !important;
}

input:focus {
  box-shadow: none !important;
}

div.map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  box-shadow: 3px 2px 37px -14px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}

div.map-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 10px;
}

.border-error {
  border-bottom: 2px solid rgb(245, 89, 61);
}

ul~div {
  background: rgb(245, 89, 61) !important;
}

.divider {
  display: flex;
}

.divider:before, .divider:after {
  content: "";
  flex: 1;
}

.line {
  align-items: center;
  margin: 1em -1em;
}

.line:before, .line:after {
  height: 1px;
  margin: 0 1em;
}

.one-line:before, .one-line:after {
  background: black;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5 !important;
}

.cancelBtn:hover {
  cursor: pointer;
}

.bg-lightGrey {
  background-color: #efefe7;
}

.bg-darkGrey {
  background-color: #a6a087;
}