@font-face {
    font-family: 'Caveat';
    src: local('Caveat'), url('./Caveat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Caveat-bold';
    src: local('Caveat-bold'), url('./Caveat-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Jura';
    src: local('Jura'), url('./Jura-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Jura-bold';
    src: local('Jura-bold'), url('./Jura-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}